import React, { PropsWithChildren } from 'react';
import {
  DeviationBlocker,
  DeviationMinor,
  IconRoleInstaller,
  IconRoleSupervisor,
  IconRoleTester,
  TabMyDeviations,
  theme,
} from '@konecorp/ui-library';
import { blue, grey, green, purple } from '@material-ui/core/colors';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Card, CardActions, CardContent, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
  ActivityDifferentiator,
  CompactNetwork,
  InstallationStatus,
  NetworkDeviationCount,
  NetworkTag,
} from '../../schemas';

export interface InstallationCardProps {
  network: CompactNetwork;
  deviations: NetworkDeviationCount | null;
  status?: InstallationStatus | null;
  role?: ActivityDifferentiator;
  firstAssignmentDate: string;
  latestAnsweredQdQuestionSet: string;
  hasQualityForm: boolean | null;
  headerColor?: 'blue' | 'grey';
  onClick?: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    gridContainer: {
      alignItems: 'center',
    },
    header: {
      alignItems: 'center',
      color: theme.palette.common.white,
      paddingBottom: theme.spacing(1),
      paddingTop: 0,
    },
    blueHeader: {
      backgroundColor: theme.palette.primary.main,
    },
    greyHeader: {
      backgroundColor: theme.palette.secondary.main,
    },
    headerText: {
      marginTop: theme.spacing(1.25),
    },
    networkDetails: {
      marginTop: theme.spacing(1.25),
    },
    milestone: {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.text.primary,
      marginLeft: theme.spacing(0.75),
      width: 30,
      height: 25,
      paddingTop: 3,
      borderRadius: 5,
      textTransform: 'uppercase',
    },
    root: {
      flexGrow: 1,
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      width: '100%',
      '& >*:last-child': {
        paddingBottom: theme.spacing(1),
      },
    },
    title: {
      color: theme.palette.primary.main,
      textTransform: 'uppercase',
    },
    deviationIcon: {
      verticalAlign: 'middle',
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    roleIcon: {
      paddingTop: 5,
      paddingLeft: theme.spacing(1.5),
    },
    footer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    warning: {
      color: theme.palette.error.main,
      '& #icon_snags': {
        fill: theme.palette.error.main,
      },
    },
    grey: {
      backgroundColor: grey[200],
    },
    green: {
      backgroundColor: green[100],
    },
    blue: {
      backgroundColor: blue[100],
    },
    purple: {
      backgroundColor: purple[100],
    },
    warningIcon: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(2),
    },
  })
);

type FooterColors = 'grey' | 'green' | 'blue' | 'purple';

const statusColors: { [status in InstallationStatus]: FooterColors } = {
  [InstallationStatus.TO_BE_STARTED]: 'grey',
  [InstallationStatus.FOR_INSTALLER_ACCEPTANCE]: 'green',
  [InstallationStatus.INSTALLER_REJECTED]: 'green',
  [InstallationStatus.INSTALLER_ACCEPTED]: 'green',
  [InstallationStatus.FOR_INSTALLATION_INSPECTION]: 'green',
  [InstallationStatus.FOR_TESTER_ACCEPTANCE]: 'blue',
  [InstallationStatus.TESTER_REJECTED]: 'blue',
  [InstallationStatus.TESTER_ACCEPTED]: 'blue',
  [InstallationStatus.FOR_FINAL_INSPECTION]: 'grey',
  [InstallationStatus.FOR_SEB_ACCEPTANCE]: 'purple',
  [InstallationStatus.SEB_REJECTED]: 'grey',
  [InstallationStatus.SEB_ACCEPTED]: 'grey',
  [InstallationStatus.INSTALLATION_COMPLETE]: 'grey',
  [InstallationStatus.INSTALLATION_ARCHIVED]: 'grey',
};

const warningStatuses = [
  InstallationStatus.TO_BE_STARTED,
  InstallationStatus.FOR_INSTALLER_ACCEPTANCE,
  InstallationStatus.FOR_TESTER_ACCEPTANCE,
];

const InstallationCard = (
  props: PropsWithChildren<InstallationCardProps>
): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    onClick,
    network,
    firstAssignmentDate,
    latestAnsweredQdQuestionSet,
    deviations,
    status,
    role,
    // hasQualityForm,
    headerColor,
  } = props;

  const getHeaderColorClassName = (headerColor?: 'blue' | 'grey') => {
    if (headerColor === 'grey') return classes.greyHeader;
    return classes.blueHeader;
  };

  const isSupervisor = role === ActivityDifferentiator.SPV;
  const isWarningStatus = !!status && warningStatuses.includes(status);

  const footerColor = (status && statusColors[status]) || 'grey';
  const showMsg = network.isModelData && !network.isTacoDataQuest;
  /*changes for EDOS-4402*/
  const getStyle = (): React.CSSProperties => {
    if (showMsg) return { color: 'red' };
    else return {};
  };
  const getText = (): string => {
    if (showMsg) return t('installationCard.noTacoDataMessage');
    else {
      return '';
    }
  };
  return (
    <Card className={classes.root} onClick={onClick} aria-label="installation-card">
      <CardContent
        className={`${classes.header} ${getHeaderColorClassName(headerColor)}`}
      >
        <Grid container className={classes.gridContainer}>
          <Grid item xs={9} className={classes.headerText}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body2">{network.description}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  {t('installationCard.networkNumber')} {network.networkNumber || ''}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  {t('installationCard.equipmentNumber')} {network.equipmentNumber || ''}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container direction="column">
              <Grid item className={classes.roleIcon} container justify="flex-end">
                {role === ActivityDifferentiator.INST && (
                  <IconRoleInstaller
                    data-testid="role-icon-installer"
                    htmlColor={theme.palette.background.paper}
                  />
                )}
                {role === ActivityDifferentiator.CMSN && (
                  <IconRoleTester
                    data-testid="role-icon-tester"
                    htmlColor={theme.palette.background.paper}
                  />
                )}
                {role === ActivityDifferentiator.SPV && (
                  <IconRoleSupervisor
                    data-testid="role-icon-supervisor"
                    htmlColor={theme.palette.background.paper}
                  />
                )}
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  className={classes.networkDetails}
                >
                  <Grid item>
                    <Typography
                      variant="body2"
                      className={classes.milestone}
                      component="div"
                      align="center"
                    >
                      {network.milestone || ''}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body2"
                      className={classes.milestone}
                      component="div"
                      align="center"
                    >
                      {network.networkTag || NetworkTag.NBS}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Box p={2}>
          <Grid container>
            <Grid item xs={6} sm={3}>
              <Typography variant="body2" className={classes.title}>
                {t('installationCard.start')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="body2">
                {firstAssignmentDate
                  ? firstAssignmentDate
                  : t('installationCard.assignmentMissing')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography className={classes.title} variant="body2">
                {t('installationCard.QD+')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="body2">
                {latestAnsweredQdQuestionSet}
                <span style={getStyle()}> {getText()} </span>{' '}
                {/* changes for EDOS-4402 */}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography className={classes.title} variant="body2">
                {t('installationCard.deviations')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="body2">
                {deviations?.normal}
                <DeviationMinor className={classes.deviationIcon} />
                {deviations?.blockers}
                <DeviationBlocker className={classes.deviationIcon} />
              </Typography>
            </Grid>
            {/* changes for EDOS-4402  */}{' '}
            {/*  <Grid item xs={6} sm={3}>
              <Typography className={classes.title} variant="body2">
                {t('installationCard.qualityForm')}
              </Typography>
            </Grid><Grid item xs={6} sm={3}>
              <Typography variant="body2">
                {hasQualityForm === true && 'Available'}
                {hasQualityForm === false && 'Unavailable'}
              </Typography>
            </Grid> */}
          </Grid>
        </Box>
      </CardActions>

      {isSupervisor && status && (
        <CardContent
          className={`${classes.footer} ${classes[footerColor]} ${
            isWarningStatus ? classes.warning : ''
          }`}
        >
          {isWarningStatus && <TabMyDeviations className={classes.warningIcon} />}
          <Typography variant="body2">
            {t(`installationCard.footerMessage.${status}`)}
          </Typography>
        </CardContent>
      )}
    </Card>
  );
};

export default InstallationCard;
