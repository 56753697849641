import React, { useContext } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { ArrowForward, CheckCircleOutlineRounded, Warning } from '@material-ui/icons';
import { theme, CircleCancel } from '@konecorp/ui-library';
import Context from '../../context';
import { useHistory } from 'react-router-dom';
import Empty from '../Empty';
import { useIfSubcontractor } from '../../hooks/useIfSubcontractor';

const useStyles = makeStyles((theme) =>
  createStyles({
    summary: {
      borderWidth: '0.1em',
      border: 'solid',
      borderColor: theme.palette.secondary.light,
      '& p': {
        lineHeight: theme.spacing(0.3),
      },
    },
    deviationGrid: {
      display: 'grid',
      gridTemplateColumns: '3fr 1fr 1fr',
    },
    dateGrid: {
      display: 'grid',
      gridTemplateColumns: '2fr 1fr',
    },
    rootPaper: {
      width: '100%',
    },
    formFailStatus: {
      fontWeight: 'bold',
      marginLeft: '16px',
      color: theme.palette.error.main,
    },
    formPassStatus: {
      fontWeight: 'bold',
      marginLeft: '16px',
      color: theme.palette.success.main,
    },
    formSummaryMargin: {
      marginBottom: '10px',
    },
    formFailedCriticalQuestions: {
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.secondary.main,
      },
      marginBottom: '10px',
    },
    forwardArrow: {
      marginLeft: '20px',
      color: theme.palette.primary.main,
    },
    truncatedText: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '200PX',
    },
    passText: {
      fontWeight: 'bold',
      color: theme.palette.success.main,
    },
    failText: {
      fontWeight: 'bold',
      color: theme.palette.error.main,
    },
  })
);

interface Question {
  pk: string;
  sk: string;
  value: string;
  mustScore: boolean;
  totalPoints: number;
  maxPoints: number;
}

interface FailedCriticalQuestions {
  questionSetId: string;
  questionIndex: number;
  questionText: string | undefined | null;
}

interface ScoreData {
  failedCriticalQuestions?: Question[];
  failedQuestions?: Question[];
  passQuestions?: Question[];
  unscoredQuestions?: Question[];
  totalPoints?: Number;
  maxPoints?: Number;
  passPercentage?: Number;
  currentPercentage?: Number;
  pass?: boolean;
  isScoreForm?: boolean;
}

interface FormScoreProps {
  scoreData: ScoreData;
  failedCriticalQuestions: FailedCriticalQuestions[];
  unAnsweredQuestionsCount: number;
  loadFlag: boolean;
}

const FormScore = (props: FormScoreProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { networkNumber } = useContext(Context);
  const history = useHistory();
  const [isSubcontractor] = useIfSubcontractor();

  const handleClick = (question: FailedCriticalQuestions) => {
    if (isSubcontractor)
      history.push(
        `/subcontractor/${networkNumber}/execution?questionSetId=${question?.questionSetId}&questionNumber=${question?.questionIndex}`
      );
    else
      history.push(
        `/${networkNumber}/execution?questionSetId=${question?.questionSetId}&questionNumber=${question?.questionIndex}`
      );
  };

  return (
    <>
      {props?.loadFlag && Object.keys(props?.scoreData).length > 0 ? (
        <>
          <Typography variant="h6" className={classes.formSummaryMargin}>
            {t('formScore.formScoreSummary')}
          </Typography>
          {props?.scoreData?.pass ? (
            <>
              <Box
                display="flex"
                alignItems="center"
                className={classes.formSummaryMargin}
              >
                <CheckCircleOutlineRounded
                  data-testid="pass-icon"
                  htmlColor={theme.palette.success.main}
                />
                <Typography variant="h6" className={classes.formPassStatus}>
                  {t('formScore.passed')}
                </Typography>
              </Box>
              <Typography>
                <Trans
                  i18nKey="formScore.passedMessage"
                  values={{ percentage: props?.scoreData?.currentPercentage }}
                  components={{ b: <strong /> }}
                />
              </Typography>
              <Typography className={classes.formSummaryMargin}>
                <Trans
                  i18nKey="formScore.passedSecondMessage"
                  values={{ failPercentage: props?.scoreData?.passPercentage }}
                  components={{ b: <strong /> }}
                />
              </Typography>
            </>
          ) : (
            <>
              <Box display="flex" alignItems="center">
                <CircleCancel
                  data-testid="fail-icon"
                  htmlColor={theme.palette.error.main}
                />
                <Typography variant="h6" className={classes.formFailStatus}>
                  {t('formScore.failed')}
                </Typography>
              </Box>
              <Typography>
                <Trans
                  i18nKey="formScore.failedMessage"
                  values={{ percentage: props?.scoreData?.currentPercentage }}
                  components={{ b: <strong /> }}
                />
              </Typography>
              <Typography className={classes.formSummaryMargin}>
                <Trans
                  i18nKey="formScore.failedSecondMessage"
                  values={{ passPercentage: props?.scoreData?.passPercentage }}
                  components={{ b: <strong /> }}
                />
              </Typography>
            </>
          )}
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            mt={3}
            height="auto"
            width="40%"
            minWidth="200px"
            style={{ backgroundColor: theme.palette.grey[200], padding: '30px' }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Typography>{t('formScore.totalQuestions')}:</Typography>
              <Typography>
                {(props?.scoreData?.failedCriticalQuestions?.length || 0) +
                  (props?.scoreData?.failedQuestions?.length || 0) +
                  (props?.scoreData?.passQuestions?.length || 0) +
                  (props?.scoreData?.unscoredQuestions?.length || 0)}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              mt={1}
            >
              <Typography>{t('formScore.answered')}:</Typography>
              <Typography>
                {(props?.scoreData?.failedCriticalQuestions?.length || 0) +
                  (props?.scoreData?.failedQuestions?.length || 0) +
                  (props?.scoreData?.passQuestions?.length || 0) +
                  (props?.scoreData?.unscoredQuestions?.length || 0) -
                  props?.unAnsweredQuestionsCount}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              mt={1}
            >
              <Typography>{t('formScore.unAnswered')}:</Typography>
              <Typography>{props?.unAnsweredQuestionsCount}</Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              mt={1}
            >
              <Typography>{t('formScore.passedQuestions')}:</Typography>
              <Typography className={classes.passText}>
                {props?.scoreData?.passQuestions?.length}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              mt={1}
            >
              <Typography>{t('formScore.failedQuestions')}:</Typography>
              <Typography className={classes.failText}>
                {props?.scoreData?.failedQuestions?.length}
              </Typography>
            </Box>
          </Box>
          {props.failedCriticalQuestions.length > 0 && (
            <>
              <Box display="flex" alignItems="center" mt={3}>
                <Warning htmlColor={theme.palette.error.main} />
                <Typography variant="h6" className={classes.formFailStatus}>
                  {t('formScore.criticalQuestionsMessage', {
                    criticalQuestions: props?.scoreData?.failedCriticalQuestions?.length,
                  })}
                </Typography>
              </Box>
              <Typography className={classes.formSummaryMargin}>
                {t('formScore.criticalQuestionsSecondMessage')}
              </Typography>
              {props?.failedCriticalQuestions?.map(
                (question: FailedCriticalQuestions, index: number) => (
                  <Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    onClick={() => handleClick(question)}
                    className={classes.formFailedCriticalQuestions}
                    data-testid="questionText"
                  >
                    <Typography variant="body1" className={classes.truncatedText}>
                      {question.questionText || 'No text available'}
                    </Typography>
                    <ArrowForward className={classes.forwardArrow} />
                  </Box>
                )
              )}
            </>
          )}
        </>
      ) : (
        <Empty displayIcon={false} message={t('notifications.fetchingFormScore')}></Empty>
      )}
    </>
  );
};

export default FormScore;
