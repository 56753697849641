import { ArrowLeft, ArrowRight } from '@konecorp/ui-library';
import { Box, Button, ButtonProps, Grid, makeStyles } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';

const useStyles = makeStyles((theme) => {
  const commonButtonStyles = {
    height: theme.spacing(5),
    lineHeight: '16px',
    '&.MuiButton-root': {
      width: '100%',
      padding: 0,
    },
  };

  return {
    content: {
      flexBasis: 'auto',
      width: theme.spacing(24),
    },
    paddingLeft: {
      paddingLeft: theme.spacing(1.5),
    },
    paddingRight: {
      paddingRight: theme.spacing(1.5),
    },
    navigationIcon: {
      width: theme.spacing(4),
      height: theme.spacing(5),
      '& path': {
        fill: theme.palette.common.white,
      },
    },
    navigationButton: {
      ...commonButtonStyles,
      '&.MuiButton-root': {
        minWidth: '40px',
        padding: 0,
      },
      '& .MuiButton-endIcon, .MuiButton-startIcon': {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    navigationButtonBackward: {
      ...commonButtonStyles,
      '& .MuiButton-label': {
        padding: theme.spacing(1),
        justifyContent: 'flex-start',
      },
    },
    navigationButtonForward: {
      ...commonButtonStyles,
      '& .MuiButton-label': {
        padding: theme.spacing(1),
        justifyContent: 'flex-end',
      },
    },
  };
});

export interface NavigationButtonsProps {
  onClickForward?: () => void;
  onClickBackward?: () => void;
  onCreateDeviation?: () => void;
  backwardButtonText?: string;
  forwardButtonText?: string;
  content?: JSX.Element;
  className?: string;
  forwardDisabled?: boolean;
}

const NavigationButton = (props: {
  isBackButton?: boolean;
  isDeviationButton?: boolean;
  onClick: () => void;
  text?: string;
  disabled?: boolean;
}) => {
  const { isBackButton, isDeviationButton, onClick, text } = props;
  const classes = useStyles();
  let className = classes.navigationButton;
  if (text) {
    if (isBackButton) {
      className = classes.navigationButtonBackward;
    } else {
      className = classes.navigationButtonForward;
    }
  }
  const buttonProps = {
    size: 'small',
    variant: 'contained',
    onClick,
    color: 'primary',
    className,
    disabled: props.disabled,
    'data-testid': isBackButton ? 'go-backward' : 'go-forward',
    endIcon:
      !isBackButton && !isDeviationButton ? (
        <ArrowRight className={classes.navigationIcon} />
      ) : undefined,
    startIcon: isBackButton ? (
      <ArrowLeft className={classes.navigationIcon} />
    ) : undefined,
  } as ButtonProps;
  return <Button {...buttonProps}>{text}</Button>;
};

const NavigationButtons = (
  props: PropsWithChildren<NavigationButtonsProps>
): JSX.Element => {
  const classes = useStyles();
  const {
    onClickBackward,
    onClickForward,
    onCreateDeviation,
    backwardButtonText,
    forwardButtonText,
    content,
    forwardDisabled,
  } = props;
  const isSmallButton = !(backwardButtonText && forwardButtonText);
  return (
    <Box flexGrow={1}>
      <Grid container justify="center" wrap="nowrap">
        <Grid
          item
          xs={isSmallButton ? 2 : 6}
          className={isSmallButton ? '' : classes.paddingRight}
        >
          {onClickBackward && (
            <NavigationButton
              text={backwardButtonText}
              isBackButton
              onClick={onClickBackward}
            />
          )}
        </Grid>
        {isSmallButton && (
          <Grid item xs={8} className={classes.content}>
            {content}
          </Grid>
        )}
        <Grid
          item
          xs={isSmallButton ? 2 : 6}
          className={isSmallButton ? '' : classes.paddingLeft}
        >
          {onClickForward && (
            <NavigationButton
              text={forwardButtonText}
              onClick={onClickForward}
              disabled={forwardDisabled}
            />
          )}
          {onCreateDeviation && (
            <NavigationButton
              text={forwardButtonText}
              onClick={onCreateDeviation}
              disabled={forwardDisabled}
              isDeviationButton
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default NavigationButtons;
